define("pilots/authenticators/admin-pretender", ["exports", "ember-simple-auth/authenticators/devise", "pilots/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminPretender = _devise.default.extend({
    session: Ember.inject.service(),
    resourceName: 'pilot',
    authenticate(pilotNumber, pilotFirstName, pilotLastName) {
      let authOptions = {
        audience: _environment.default['ember-simple-auth'].auth0.audience,
        redirectUri: _environment.default['ember-simple-auth'].auth0.redirectUri,
        responseType: 'token id_token',
        scope: 'openid email',
        state: 'login',
        is_admin_mode: true,
        pilot_params: {
          first_name: pilotFirstName,
          last_name: pilotLastName,
          number: pilotNumber
        }
      };
      this.get('session').authenticate('authenticator:auth0-universal', authOptions, err => {
        if (err) {
          console.log("Auth0 err", err);
        }
      });
    }
  });
  var _default = AdminPretender;
  _exports.default = _default;
});