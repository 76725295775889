define("pilots/routes/clientmissionupload", ["exports", "pilots/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin", "pilots/mixins/s3-asset-uploads"], function (_exports, _environment, _authenticatedRouteMixin, _s3AssetUploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientMissionUploadRoute;
  ClientMissionUploadRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _s3AssetUploads.default, {
    model: function (params) {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        images: this.store.query('image', {
          missionId: params.mission_id
        }),
        mission: this.store.find('mission', params.mission_id).then(function (_this) {
          return function (response) {
            if (response.get('status') === 'pilots_notified') {
              _this.replaceWith('availablemission', response.id);
            }
            return response;
          };
        }(this), function (_this) {
          return function (error) {
            console.log("error loading ClientMissionRoute: " + error);
            return _this.replaceWith('fourOhFour', {});
          };
        }(this))
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('adminMode', this.get('session.adminMode'));
    },
    actions: {
      update: function (mission) {
        return mission.save();
      }
    }
  });
  var _default = ClientMissionUploadRoute;
  _exports.default = _default;
});