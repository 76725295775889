define("pilots/components/address-lookup", ["exports", "pilots/mixins/google-init-mixin"], function (_exports, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AddressLookupComponent, SWEDEN_ISO_CODE, TURKEY_ISO_CODE, UK_ISO_CODE;
  UK_ISO_CODE = 'GB';
  SWEDEN_ISO_CODE = 'SE';
  TURKEY_ISO_CODE = 'TR';
  AddressLookupComponent = Ember.Component.extend(_googleInitMixin.default, {
    afterGoogleLoaded: function () {
      var autocomplete, input;
      input = this.element.querySelectorAll('input')[0];
      autocomplete = new google.maps.places.SearchBox(input);
      google.maps.event.addDomListener(input, 'keydown', function (e) {
        if (e.keyCode === 13) {
          return e.preventDefault();
        }
      });
      return autocomplete.addListener('places_changed', function (_this) {
        return function () {
          var address_components, city, country_code, place, ref;
          place = autocomplete.getPlaces()[0];
          if (!(place && place.geometry)) {
            _this.set('changeset.longitude', null);
            _this.set('changeset.latitude', null);
            _this.set('error', 'Not a valid address. Please try again.');
            _this.set('showError', true);
            return;
          }
          address_components = {};
          Em.$.each(place.address_components, function (k, v1) {
            Em.$.each(v1.types, function (k2, v2) {
              address_components[v2] = v1;
            });
          });
          if (address_components.street_number && address_components.route) {
            _this.set('changeset.address', address_components.street_number.long_name + " " + address_components.route.long_name);
          } else if (address_components.route) {
            _this.set('changeset.address', address_components.route.long_name);
          } else {
            _this.set('changeset.address', null);
          }
          country_code = (ref = address_components.country) != null ? ref.short_name : void 0;
          if (country_code) {
            _this.set('changeset.country', country_code);
          } else {
            _this.set('changeset.country', null);
          }
          if (address_components.administrative_area_level_1) {
            _this.set('changeset.state', address_components.administrative_area_level_1.long_name);
          } else {
            _this.set('changeset.state', null);
          }
          city = function () {
            switch (false) {
              case country_code !== TURKEY_ISO_CODE:
                return address_components.administrative_area_level_1;
              case country_code !== UK_ISO_CODE && country_code !== SWEDEN_ISO_CODE:
                return address_components.postal_town;
              default:
                return address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood || address_components.sublocality;
            }
          }();
          if (city) {
            _this.set('changeset.city', city.long_name);
          } else {
            _this.set('changeset.city', null);
          }
          if (address_components.postal_code) {
            _this.set('changeset.postal_code', address_components.postal_code.long_name);
          } else {
            _this.set('changeset.postal_code', null);
          }
          _this.set('changeset.longitude', place.geometry.location.lng());
          return _this.set('changeset.latitude', place.geometry.location.lat());
        };
      }(this));
    },
    actions: {
      showError: function () {
        return this.set('showError', true);
      }
    }
  });
  var _default = AddressLookupComponent;
  _exports.default = _default;
});