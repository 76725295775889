define("pilots/routes/welcome", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jshint ignore:line

  const WelcomeRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    tagName: '',
    sessionAccount: Ember.inject.service(),
    model() {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId')
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideNav', true);
      return this.controllerFor('application').set('adminMode', this.get('session.adminMode'));
    }
  });
  var _default = WelcomeRoute;
  _exports.default = _default;
});