define("pilots/routes/profile", ["exports", "pilots/data/iso_country", "pilots/data/travel_distance", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _iso_country, _travel_distance, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service(),
    queryParams: {
      c: {
        refreshModel: false
      },
      le: {
        refreshModel: false
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideNav', false);
      model.sortedDrones = model.drones.toArray().sortBy('full_name');
      return this.controllerFor('application').set('adminMode', !!this.get('session.adminMode'));
    },
    model: function () {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        drones: this.store.findAll('drone'),
        pilotDrones: this.store.findAll('pilot-drone'),
        licenses: this.store.findAll('license'),
        pilotLicenses: this.store.findAll('pilot-license'),
        travelDistance: _travel_distance.default,
        countries: _iso_country.default,
        pilotEquipment: this.store.findAll('pilot-pilot-equipment'),
        equipment: this.store.findAll('pilot-equipment'),
        pilotDevices: this.store.findAll('pilot-device'),
        devices: this.store.findAll('device'),
        availableLegalEntities: this.store.findAll('legal-entity')
      });
    }
  });
  _exports.default = _default;
});