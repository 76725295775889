define("pilots/routes/clientmissionintro", ["exports", "pilots/data/iso_country", "pilots/data/travel_distance", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _iso_country, _travel_distance, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientMissionIntro;
  ClientMissionIntro = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function (controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('adminMode', this.get('session.adminMode'));
    }
  });
  var _default = ClientMissionIntro;
  _exports.default = _default;
});