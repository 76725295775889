define("pilots/routes/signup-flow-preferences", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "pilots/data/iso_country", "pilots/data/travel_distance"], function (_exports, _authenticatedRouteMixin, _iso_country, _travel_distance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SignupFlowPreferencesRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    tagName: '',
    sessionAccount: Ember.inject.service(),
    model() {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        travelDistance: _travel_distance.default,
        countries: _iso_country.default
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideNav', true);
      return this.controllerFor('application').set('adminMode', this.get('session.adminMode'));
    },
    actions: {
      willTransition: function () {
        this.controllerFor('application').set('hideNav', false);
      }
    }
  });
  var _default = SignupFlowPreferencesRoute;
  _exports.default = _default;
});