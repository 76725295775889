define("pilots/routes/clientspage", ["exports", "pilots/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClientsPageRoute;
  ClientsPageRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function (params) {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId')
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('adminMode', this.get('session.adminMode'));
    }
  });
  var _default = ClientsPageRoute;
  _exports.default = _default;
});